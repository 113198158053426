<template>
  <main>
    <div class="headline">Join to ShairMail</div>
    <p class="mt-5">
      Powered by LedgerMail - World’s 1st Decentralized Email Solution.
    </p>

    <v-form
      method="post"
      class="auth-form mb-5"
      ref="userSignupForm"
      lazy-validation
      v-model="valid"
      @submit.prevent="submit()"
    >
      <v-text-field
        class="pt-5"
        label="Enter your e-mail address"
        v-model="email"
        :rules="emailRules"
        required
        name="email"
      >
      </v-text-field>

      <v-btn
        width="150"
        @click="submit"
        class="teal accent-4 white--text"
        :disabled="!valid"
        >Verify E-mail
      </v-btn>
    </v-form>

    <v-divider></v-divider>
    <v-container>
      <span>Already have an account? </span>
      <router-link to="/auth/login">Login</router-link>
    </v-container>
  </main>
</template>

<script>
export default {
  data() {
    return {
      valid: false,
      showPassword: true,
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
    };
  },
  methods: {
    async submit() {
      if (!this.$refs.userSignupForm.validate()) return;

      this.$store
        .dispatch("NEW_USER", this.email)
        .then(() => {
          this.$router.push("/auth/email-verification");
        })
        .catch((err) => {
          this.$store.dispatch("ALERT_ERROR", err);
        });
    },
  },
};
</script>
